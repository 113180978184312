import { useEffect, useState } from 'react'
import utils from 'services/utils'
import styled from 'styled-components'
import { Modal } from 'uic'
import NewDistingoBankAppModal from './NewDistingoBankAppModal'
import { URLS } from 'services/constants'
import BORequester from 'services/api/BORequester'

const NEW_APP_MODAL_ID = 'newAppModalId'

const NewDistingoBankAppModalWrapper = styled.div`
  dialog {
    padding: 0;
    position: relative;
    overflow: hidden;
    background: linear-gradient(135deg, #c9c5e1, #ffffff);
    max-width: 450px;
    border-radius: 20px 20px 0 0;

    .closeModal {
      display: none;
    }
  }
`

const NewDistingoBankAppModalContainer = () => {
  const location = window.location

  const localUsersSettings = utils.app.getLocal('localUsersSettings')

  const [popupBOEnabled, setPopupBOEnabled] = useState(false)
  const [showNewAppModalState, setShowNewAppModalState] = useState(false)
  const [newAppModalId, setNewAppModalId] = useState(utils.app.getLocal(NEW_APP_MODAL_ID))

  useEffect(() => {
    const boRequester = new BORequester()
    const fetchBOSettings = async () => {
      const { data } = await boRequester.getSettings()
      const pagesStatus = data.pages_setting || []
      const coachPopupSetting = pagesStatus.filter((page) => page.slug === 'coach_pop_up')?.[0]
      setPopupBOEnabled(coachPopupSetting?.enabled)
    }
    fetchBOSettings()
  }, [])

  if (!newAppModalId) {
    utils.app.setLocal(NEW_APP_MODAL_ID, Date.now())
    setNewAppModalId(utils.app.getLocal(NEW_APP_MODAL_ID))
  }

  useEffect(() => {
    const hasSeenNewAppModal =
      localUsersSettings && localUsersSettings[newAppModalId]
        ? localUsersSettings[newAppModalId]?.hasSeenNewAppModal || false
        : false
    const showNewAppModal = popupBOEnabled && !hasSeenNewAppModal

    setShowNewAppModalState(showNewAppModal)
  }, [localUsersSettings, newAppModalId, popupBOEnabled])

  const saveSettings = (type, bool) => {
    const userSettings =
      localUsersSettings && localUsersSettings[newAppModalId]
        ? localUsersSettings[newAppModalId]
        : {}
    const newUsersSettings = {
      ...localUsersSettings,
      [newAppModalId]: { ...userSettings, [type]: bool },
    }
    utils.app.setLocal('localUsersSettings', newUsersSettings)
  }

  const onTryNowClick = () => {
    saveSettings('hasSeenNewAppModal', true)
    setShowNewAppModalState(false)
  }

  const onLaterClick = () => {
    saveSettings('hasSeenNewAppModal', true)
    setShowNewAppModalState(false)
  }

  if (location.hash !== '#/login' && location.hash !== '#/') {
    return null
  }

  return (
    <NewDistingoBankAppModalWrapper>
      {showNewAppModalState && (
        <Modal onClose={() => {}}>
          <NewDistingoBankAppModal
            tryNowUrl={URLS.iosApp}
            onTryNow={onTryNowClick}
            onLater={onLaterClick}
          />
        </Modal>
      )}
    </NewDistingoBankAppModalWrapper>
  )
}

export default NewDistingoBankAppModalContainer
