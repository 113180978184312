export const URLS = {
  distingo: 'https://distingobank.fr/documentation-livret',
  dat: 'https://distingobank.fr/documentation-compte-a-terme',
  faq: 'https://distingobank.fr/contact/?app',
  charteMediation:
    'https://distingobank.fr/wp-content/uploads/2023/03/01_Charte_mediation_CONSO_WEB_2.pdf',
  facebook: 'https://www.facebook.com/distingobank',
  twitter: 'https://twitter.com/distingobank',
  linkedin: 'https://www.linkedin.com/company/distingobank/',
  infos_legal: 'https://distingobank.fr/informations-legales/?source=app',
  cgu: {
    preprod: 'https://preprod.psab.ovh/conditions-generales/?source=app',
    prod: 'https://distingobank.fr/conditions-generales/?source=app',
  },
  dataPrivacy: 'https://distingobank.fr/politique-des-donnees-personnelles/',
  garantieDepots: 'https://distingobank.fr/garantie-des-depots/',
  alertFraudes: 'https://distingobank.fr/alertes-fraudes/',
  posts: 'https://distingobank.fr/wp-json/wp/v2/posts/?_embed',
  msfData: 'https://distingobank.fr/api/msf-data.php',
  conditionsLivret: 'https://distingobank.fr/conditions-generales-livret-distingo',
  noticeLivret: 'https://distingobank.fr/notice-information-livret',
  conventionSignature: 'https://distingobank.fr/convention-signature-electronique',
  conditionsTarifaires: 'https://distingobank.fr/conditions-tarifaires-distingobank',
  conditionsCAT: 'https://distingobank.fr/conditions-generales-cat',
  noticeCAT: 'https://distingobank.fr/notice-information-compte-a-terme',
  iosApp: 'https://apps.apple.com/fr/app/distingo/id587771792',
}

export const _APP_PATHS = {
  login: '/login',
  kyc: '/kyc',
  resetPassword: '/resetPassword',
  lostPassword: '/lostPassword',
  home: '/',
  my_accounts: '/my_accounts',
  minor_accounts: '/minor_accounts',
  proxy_accounts: '/proxy_accounts',
  transfer: '/transfer',
  recipients: '/recipient',
  openTermAccount: '/openTermAccount',
  openTermAccountCallback: '/openTermAccountCallback',
  openFlashTermAccount: '/openFlashTermAccount',
  openFlashTermAccountCallback: '/openFlashTermAccountCallback',
  openGreenTermAccount: '/openGreenTermAccount',
  openGreenTermAccountCallback: '/openGreenTermAccountCallback',
  openMinorAccount: '/openMinorAccount',
  openMinorAccountCallback: '/openMinorAccountCallback',
  fidelityOffer: '/fidelityOffer',
  sponsorshipOffer: '/sponsorshipOffer',
  sponsorshipEvents: '/sponsorshipEvents',
  coach: '/menu_coach',
  contracts: '/myspaceContracts',
  myInfos: '/myspaceInformations',
  myRib: '/myspaceRib',
  myForms: '/myspaceForms',
  closure: '/closure',
  myPassword: '/myPassword',
  tanEnrol: '/tanEnrol',
  tanManagement: '/tanManagement',
  contact: '/contact',
  accessibility: '/declaration-accessibilite',
  legal_mentions: '/menu_legal_mentions',
  cgu: '/menu_cgu',
  logout: '/logout',
}

export const ROUTES_CONF = {
  /* home page */
  menu_home: {
    name: 'menu_home',
    path: _APP_PATHS.home,
    isHidden: false,
  },
  /* accounts */
  menu_accounts_my_accounts: {
    name: 'menu_accounts_my_accounts',
    path: _APP_PATHS.my_accounts,
    group: 'menu_accounts',
    isHidden: false,
  },
  menu_accounts_minor_accounts: {
    name: 'menu_accounts_minor_accounts',
    path: _APP_PATHS.minor_accounts,
    group: 'menu_accounts',
    isHidden: false,
  },
  menu_accounts_proxy_accounts: {
    name: 'menu_accounts_proxy_accounts',
    path: _APP_PATHS.proxy_accounts,
    group: 'menu_accounts',
    isHidden: false,
  },
  /* transfers */
  menu_transfers_link_1: {
    name: 'menu_transfers_link_1',
    path: _APP_PATHS.transfer,
    group: 'menu_transfers',
    isHidden: false,
  },
  menu_transfers_link_2: {
    name: 'menu_transfers_link_2',
    path: '/transferHistory',
    group: 'menu_transfers',
    isHidden: false,
  },
  menu_transfers_link_3: {
    name: 'menu_transfers_link_3',
    path: _APP_PATHS.recipients,
    group: 'menu_transfers',
    isHidden: false,
  },
  /* offers */
  menu_my_offers_link_1: {
    name: 'menu_offers_CAT',
    path: _APP_PATHS.openTermAccount,
    group: 'menu_my_offers',
    isHidden: false,
  },
  menu_open_account_callback: {
    name: 'menu_open_account_callback',
    path: _APP_PATHS.openTermAccountCallback,
    group: 'menu_my_offers',
    isHidden: true,
  },
  menu_my_offers_cat_flash: {
    name: 'menu_offers_cat_flash',
    path: _APP_PATHS.openFlashTermAccount,
    group: 'menu_my_offers',
    isHidden: false,
  },
  menu_open_flash_account_callback: {
    name: 'menu_open_flash_account_callback',
    path: _APP_PATHS.openFlashTermAccountCallback,
    group: 'menu_my_offers',
    isHidden: true,
  },
  menu_my_offers_cat_green: {
    name: 'menu_offers_cat_green',
    path: _APP_PATHS.openGreenTermAccount,
    group: 'menu_my_offers',
    isHidden: false,
  },
  menu_open_green_account_callback: {
    name: 'menu_open_green_account_callback',
    path: _APP_PATHS.openGreenTermAccountCallback,
    group: 'menu_my_offers',
    isHidden: true,
  },
  menu_my_offers_link_2: {
    name: 'menu_offers_mineur',
    path: _APP_PATHS.openMinorAccount,
    group: 'menu_my_offers',
    isHidden: false,
  },
  menu_open_minor_account_callback: {
    name: 'menu_open_minor_account_callback',
    path: _APP_PATHS.openMinorAccountCallback,
    group: 'menu_my_offers',
    isHidden: true,
  },
  menu_my_offers_link_4: {
    name: 'menu_my_offers_link_4',
    path: _APP_PATHS.fidelityOffer,
    group: 'menu_my_offers',
    isHidden: false,
  },
  /* sponsorship */
  menu_sponsorship_offer: {
    name: 'menu_sponsorship_offer',
    path: _APP_PATHS.sponsorshipOffer,
    group: 'menu_sponsorship',
    isHidden: false,
  },
  menu_sponsorship_events: {
    name: 'menu_sponsorship_events',
    path: _APP_PATHS.sponsorshipEvents,
    group: 'menu_sponsorship',
    isHidden: false,
  },
  /* coach */
  menu_coach: {
    name: 'menu_coach',
    path: _APP_PATHS.coach,
    isHidden: false,
  },
  /* statements & contracts */
  menu_my_space_contracts_link_2_web: {
    name: 'menu_my_space_contracts_link_2_web',
    path: _APP_PATHS.contracts,
    isHidden: false,
  },
  /* my space */
  menu_my_space_infos_link_1: {
    name: 'menu_my_space_infos_link_1',
    path: _APP_PATHS.myInfos,
    group: 'menu_my_space',
    isHidden: false,
  },
  menu_my_space_rib_link_3: {
    name: 'menu_my_space_rib_link_3',
    path: _APP_PATHS.myRib,
    group: 'menu_my_space',
    isHidden: false,
  },
  menu_my_space_forms_link_5: {
    name: 'menu_my_space_forms_link_5',
    path: _APP_PATHS.myForms,
    group: 'menu_my_space',
    isHidden: false,
  },
  menu_my_space_closure: {
    name: 'closure_menu_label',
    path: _APP_PATHS.closure,
    group: 'menu_my_space',
    isHidden: false,
  },
  /* security */
  menu_security_password: {
    name: 'menu_my_space_infos_link_2_new',
    path: _APP_PATHS.myPassword,
    group: 'menu_security',
    isHidden: false,
  },
  menu_tan_enrol: {
    name: 'menu_tan_enrol',
    path: _APP_PATHS.tanEnrol,
    group: 'menu_security',
    isHidden: false,
  },
  menu_tan_manage: {
    name: 'menu_tan_manage',
    path: _APP_PATHS.tanManagement,
    group: 'menu_security',
    isHidden: false,
  },
  /* contact */
  menu_contact: {
    name: 'menu_contact',
    path: _APP_PATHS.contact,
    isHidden: false,
  },
  /* secondary */
  menu_accessibility: {
    name: 'menu_accessibility',
    path: _APP_PATHS.accessibility,
    level: 'secondary',
    isHidden: true,
  },
  menu_legal_mentions: {
    name: 'menu_legal_mentions',
    path: _APP_PATHS.legal_mentions,
    level: 'secondary',
    isHidden: true,
  },
  menu_cgu: {
    name: 'menu_cgu',
    path: _APP_PATHS.cgu,
    level: 'secondary',
    isHidden: true,
  },
  menu_logout: {
    name: 'menu_logout',
    path: _APP_PATHS.logout,
    level: 'secondary',
    isHidden: false,
  },
}

export const _API_URLS = {
  getNationalities: '/api-psa/pub/v1/nationalities',
  getCountries: '/api-psa/pub/v1/countries',
  getTowns: (countryId, zipCode) => {
    return `/api-psa/pub/v1/countries/${countryId}/towns?zipCode=${zipCode}`
  },
  getProfessions: '/api-psa/pub/v1/professions',
  getBusinessSectors: '/api-psa/pub/v1/businesssectors',
  postEmailContact: '/api-psa/priv/v1/contact/emails',
  postCallContact: '/api-psa/priv/v1/demande-appel',
  postToken: '/api-psa/pub/v1/oauth-password-public/token',
  getRevoke: '/api-psa/pub/v1/oauth-password-public/revoke',
  getAccounts: '/api-psa/priv/accountapi/v1/customers/me/accounts',
  getBalances: (accountNo) => {
    return `/api-psa/priv/accountapi/v1/customers/me/accounts/${accountNo}/balances`
  },
  getSaving: (accountNo) => {
    return `/api-psa/priv/accountapi/v1/customers/me/accounts/${accountNo}/savingAccount`
  },
  getPromotionalOffers: (accountNo) => {
    return `/api-psa/priv/accountapi/v1/customers/me/accounts/${accountNo}/promotionalOffers`
  },
  getInterests: (accountNo) => {
    return `/api-psa/priv/accountapi/v1/customers/me/accounts/${accountNo}/savingInterests`
  },
  getTransactions: (accountNo) => {
    return `/api-psa/priv/accountapi/v1/customers/me/accounts/${accountNo}/transactions`
  },
  getPerson: '/api-psa/priv/personapi/v1/persons/me',
  getSponsorEvents: '/api-psa/priv/personapi/v1/person/me/sponsorEvents',
  postSponsoredData: '/api-psa/priv/personapi/v1/persons/me/sponsoredDatas',
  getTransfers: (cryptedAccountNumber) => {
    return `/api-psa/priv/webtransferapi/v1/transfers/${cryptedAccountNumber}`
  },
  deleteBeneficiaries: (id) => {
    return `/api-psa/priv/webtransferapi/v1/beneficiaries/${id}`
  },
  getTransferAccounts: '/api-psa/priv/webtransferapi/v1/accounts',
  getBeneficiaries: '/api-psa/priv/webtransferapi/v1/beneficiaries',
  postTransfer: (debitAccountNo, creditAccountNo) => {
    return `/api-psa/priv/webtransferapi/v1/transfers/${debitAccountNo}/account/${creditAccountNo}`
  },
  postTransferExternal: (debitAccountNo, creditAccountNo) => {
    return `/api-psa/priv/webtransferapi/v1/transfers/${debitAccountNo}/beneficiary/${creditAccountNo}`
  },
  postEmail: '/api-psa/priv/personapi/v1/person/email',
  postPhones: '/api-psa/priv/personapi/v1/person/phones',
  getDocuments: '/api-psa/priv/v1/documentapi/documents',
  postPassword: '/api-psa/pub/v1/securityapi/password',
  postPasswordRescue: '/api-generic/v1/auth/passwordRescuePSA/psa',
  patchConsents: (id) => {
    return `/api-psa/priv/personapi/v1/persons/me/consents/${id}`
  },
  getTauxCat: '/api-psa/priv/v1/customers/tauxCat',
  postCatCreate: '/api-psa/priv/v1/folder/creer',
  putCatUpdate: '/api-psa/priv/v1/folder/compteATerme/maj',
  postCatSign: '/api-psa/priv/v1/folder/signer',
  getRLShortDetail: '/api-psa/priv/personapi/v1/persons/me/shortDetail',
  getPromotions: '/api-psa/priv/v1/folderMinor/promotions',
  postMinorCreate: '/api-psa/priv/v1/folderMinor/creer',
  putMinorUpdate: '/api-psa/priv/v1/folderMinor/livret/maj',
  postMinorFiles: '/api-psa/priv/v1/folderMinor/pj/ajouter',
  postMinorSign: '/api-psa/priv/v1/folderMinor/signer',
  postMinorOfflineSign: '/api-psa/priv/v1/folderMinor/signerPapier',
  getKyc: '/api-psa/priv/personapi/v1/persons/kyc',
  postKyc: '/api-psa/priv/personapi/v1/persons/kyc',
  getPortabilityData: '/api-psa/priv/personapi/v1/person/myDataLoad',
  getTanStatus: '/api-generic/v1/tan/status',
  postRegisterTanPhone: '/api-generic/v1/tan/registerPhone',
  postRegisterTanService: '/api-generic/v1/tan/registerService',
  postActivateTanService: '/api-generic/v1/tan/activateService',
}

// default country for react-phone-input-2
export const defaultTanCountry = {
  countryCode: 'fr',
  dialCode: '33',
  format: '+.. . .. .. .. ..',
  name: 'France',
}

export const _TAN_COUNTRIES = [
  'ca',
  'us',
  'do',
  'ru',
  'za',
  'gr',
  'nl',
  'be',
  'fr',
  'es',
  'hu',
  'it',
  'ro',
  'ch',
  'at',
  'gb',
  'dk',
  'se',
  'no',
  'pl',
  'de',
  'pe',
  'mx',
  'ar',
  'br',
  'cl',
  'co',
  've',
  'my',
  'au',
  'id',
  'ph',
  'nz',
  'sg',
  'th',
  'jp',
  'kr',
  'vn',
  'cn',
  'in',
  'ma',
  'dz',
  'tn',
  'ci',
  'mu',
  'ga',
  'et',
  'dj',
  'yt',
  're',
  'pt',
  'lu',
  'ie',
  'mt',
  'fi',
  'mc',
  'hr',
  'cz',
  'pm',
  'gp',
  'bl',
  'mf',
  'gf',
  'mq',
  'wf',
  'nc',
  'pf',
  'hk',
  'tw',
  'lb',
  'ae',
  'il',
  'bh',
  'qa',
]

export const MARITAL_STATUS = [
  { labelKey: 'family_situation_single', value: 'C' },
  { labelKey: 'family_situation_free_union', value: 'U' },
  { labelKey: 'family_situation_divorced', value: 'D' },
  { labelKey: 'family_situation_married', value: 'M' },
  { labelKey: 'family_situation_civil_union', value: 'P' },
  { labelKey: 'family_situation_separated', value: 'S' },
  { labelKey: 'family_situation_widowed', value: 'V' },
]

export const INCOMES = [
  { labelKey: 'incomes_from_1_to_1500', value: 'Inf1500' },
  { labelKey: 'incomes_from_1500_to_3000', value: 'De1500A3000' },
  { labelKey: 'incomes_from_3000_to_5000', value: 'De3000A5000' },
  { labelKey: 'incomes_from_5000_to_8000', value: 'De5000A8000' },
  { labelKey: 'incomes_greater_than_8000', value: 'Sup8000' },
]

export const ESTATE = [
  { labelKey: 'estate_less_than_100k', value: 'Inf100000' },
  { labelKey: 'estate_from_100_to_300k', value: 'De100001A300000' },
  { labelKey: 'estate_from_300_to_600k', value: 'De300001A600000' },
  { labelKey: 'estate_from_600_to_1000k', value: 'De600001A1000000' },
  { labelKey: 'estate_greater_than_1000k', value: 'Sup1000000' },
]

export const WORK_STATUS = [
  { labelKey: 'work_status_active', value: '1' },
  { labelKey: 'work_status_apprentice', value: '2' },
  { labelKey: 'work_status_inactive', value: '3' },
  { labelKey: 'work_status_student', value: '5' },
]

export const INACTIVE_PROFESSIONS = [
  { labelKey: 'inactive_profession_jobseeker', value: 8100 },
  { labelKey: 'inactive_profession_retired', value: 7000 },
  { labelKey: 'inactive_profession_no_work', value: 8500 },
]

export const PROFESSIONS = {
  'AGRI.PT.EX': 'Agriculteur',
  ARTISAN: 'Artisan',
  COMMERCANT: 'Commerçant',
  'CHEF ENTRE': "Chef d'entreprise",
  'PROF LIBER': 'Profession libérale',
  'CADR.FCT.P': 'Cadre de la fonction publique',
  'CADR ADMIN': 'Cadre du secteur privé',
  ADMINIST: 'Employé de la fonction publique',
  'ADMIN ENTR': 'Employé du secteur privé',
  TECHNICIEN: 'Technicien',
  'OUV.QUAL.I': 'Ouvrier',
  'DEM EMPLOI': "Demandeur d'emploi",
  'SANS PROFS': 'Sans activité professionnelle',
}

export const BUSINESS_SECTORS = {
  'ADM. PUBLIQUE, EDUC.': 'Administration publique, Education, Formation',
  AERONAUTIQUE: 'Aéronautique',
  AGROALIMENTAIRE: 'Agroalimentaire',
  AUDIOVISUEL: 'Audiovisuel',
  AUTOMOBILE: 'Automobile',
  'BANQUE, ASSURANCE FINANCE': 'Banque, Assurance, Finance',
  'BOIS, PAPIER, CARTON IMPRIMERIE': 'Bois, Papier, Carton, Imprimerie',
  'CHIMIE, PARACHIMIE': 'Chimie, Parachimie',
  'COMMERCE DE DETAIL': 'Commerce de détail',
  "COMMERCE DE L'ART": "Commerce de l'art",
  'COMMERCE DU JEU': 'Commerce du jeu',
  'COMM., DOCU., INFORM., MARKETING': 'Communication, Information, Marketing',
  'DEFENSE, SECURITE': 'Défense, Sécurité',
  'ELECTRONIQUE, ELECTRICITE': 'Electronique, Electricité',
  'ENERGIE, ARMEMENT, MARCHES PUBL.': 'Energie, Armement, Marchés publiques',
  'ETUDES, CONSEIL': 'Etudes et conseils',
  'GRANDE DISTRIBUTION': 'Grande distribution',
  'IMMOBIL., BATIMENT, TRAVAUX PUB.': 'Immobilier, Bâtiment, Travaux publiques',
  INFORMATIQUE: 'Informatique',
  JUSTICE: 'Justice',
  MECANIQUE: 'Mécanique',
  METALLURGIE: 'Métallurgie',
  'NEGOCE, IMPORT EXPORT': 'Négoce, Import, Export',
  'RESTAURATION, HEBERGEMENT': 'Restauration, Hébergement',
  SANTE: 'Santé',
  SPORT: 'Sport',
  TELECOMMUNICATIONS: 'Télécommunications',
  'TRANSPORT, LOGISTIQUE': 'Transports, Logistique',
}

export const SESSION_TIMEOUT = 10 * 60 * 1000
export const SESSION_TIMEOUT_ALERT = 60 * 1000

export const GTM_ID = 'GTM-MB6G58F'

export const COACH_COLORS = {
  mainColors: ['#fe9587', '#fae365', '#6de3dc', '#7473b7'],
  projectsAltColors: ['#f6e1d8', '#f9f1c4', '#d8efef', '#e2e2ed'],
  allocAltColors: ['#f6e1d8', '#f9f1c4', '#d8efef', '#d7d7ec'],
}

export const FLASH_COUNTDOWN_URL =
  'https://mail-img.fr/email-countdown-timer-master/distingo.php?time=2023-09-22+23:59:00'

export const _CONF = {
  _LOST_PASSWORD_FORM: true,
}
